<template>
<v-card elevation="0">
    <v-card-text>
    <v-data-table :headers="headers"
        :items="broadcastlog"
        class="elevation-1"
        sort-by="timestamp"
        sort-desc
    >
        <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title>Broadcast Log</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field v-model="keyword" placeholder="Cari..." hide-details></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog scrollable v-model="dialog" max-width="700px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                        New Broadcast
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title>
                    <span class="headline">New Broadcast</span>
                    </v-card-title>

                    <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <span class="">Send To</span>
                              <v-radio-group v-model="editedItem.sendto" row dense>
                                <v-radio label="Admin Only (Test)" value="admin"></v-radio>
                                <v-radio label="All" value="all"></v-radio>
                              </v-radio-group>       
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <span class="">On Tap Notif Navigate To</span>
                              <v-autocomplete
                                v-model="editedItem.activity"
                                :items="items"
                                :loading="isLoading"
                                :search-input.sync="search"
                                hide-no-data
                                hide-selected
                                item-text="SearchText"
                                item-value="_id"
                                label="Activities"
                                placeholder="Start typing to Search"
                                prepend-icon="mdi-database-search"
                                clearable
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea v-model="editedItem.content" label="Content" outlined></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                            Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="send">
                            Send Broadcast
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Reset
            </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-overlay :value="onProgress" style="z-index:999">
        <v-progress-circular :size="50" color="amber" indeterminate></v-progress-circular>
    </v-overlay>  
</v-card>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      headers: [
        {
          text: 'Send To', align: 'start', value: 'sendto',
        },
        { text: 'Content', value: 'content' },
        { text: 'Activity', value: 'activity' },
        { text: 'Time', value: 'timestamp' },
      ],
      editedIndex: -1,
      editedItem: {
        sendto: 'admin',
        content: '',
        activity: '',
      },
      defaultItem: {
        sendto: 'admin',
        content: '',
        activity: '',
      },
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      keyword: '',
      broadcastlog: [],
      descriptionLimit: 100,
      onProgress: false
    }),

    computed: {
      items () {
        return this.entries.map(entry => {
          const SearchText = entry.name.length > this.descriptionLimit
            ? entry.name.slice(0, this.descriptionLimit) + '...'
            : entry.name

          return Object.assign({}, entry, { SearchText })
        })
      },
      apihost() {
        return this.$store.state.config.apiHost
      }
    },

    created () {
      this.initialize()
    },


    methods: {
      initialize () {
        this.$store.dispatch('broadcast/getObjs').then(response => {
            this.broadcastlog = response
        })
      },

      editItem (item) {
        this.editedIndex = this.broadcastlog.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },


      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },


      async send () {
        this.onProgress = true
        await this.$store.dispatch('broadcast/postObj', this.editedItem)
        this.dialog = false
        this.onProgress = false
        this.initialize()
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      search () {
        // Items have already been loaded
        if (this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        // Lazily load input items
        fetch(this.apihost + 'activities')
          .then(res => res.json())
          .then(res => {
            this.count = res.length
            this.entries = res
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },
    },    
  }
</script>